.StepNavBar {
  display: flex;
  flex-direction: column;
  align-items: center;

  .StepNavBar__bar li {
    list-style-type: none;
    width: 100px;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: var(--secondary);
  }

  .StepNavBar__bar li:before {
    width: 32px;
    height: 32px;
    content: "";
    line-height: 32px;
    border: 1px dashed var(--secondary);
    display: block;
    text-align: center;
    margin: 0 auto 3px auto;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    background-color: #fff;
  }
  .StepNavBar__bar li:after {
    width: 100%;
    height: 1px;
    content: '';
    position: absolute;
    background-color: var(--secondary);
    top: 16px;
    left: -50%;
    z-index: 0;
  }
  .StepNavBar__bar li:first-child:after {
    content: none;
  }

  .StepNavBar__bar li.StepNavBar__active {
    color: var(--primary);
    font-weight: bold;
  }
  .StepNavBar__bar li.StepNavBar__active:before {
    border: 1px solid var(--primary);
    background: #fff;
  }
  .StepNavBar__bar li.StepNavBar__active + li:after {
    background-color: var(--primary);
  }


  .StepNavBar__bar {
    counter-reset: step;
  }
  .StepNavBar__bar li:before {
    content: counter(step);
    counter-increment: step;
  }

}
